<template>
  <div>

    <!-- #region::Skeleton zone -->
    <template v-if="loadingQuote">
      <SaveQuoteFormSkeleton />
    </template>
    <!-- #endregion::Skeleton zone -->

    <template v-else>
      <div class="mb-2">

        <!-- #region::Quote details options -->
        <QuoteDetailsOptions
          class="mb-1"
          @reload-quote="loadQuoteData"
        />
        <!-- #endregion::Quote details options -->

        <QuoteCustomerData :quote="quote" />
        <QuoteExtraData :quote="quote" />
        <QuoteProductsDetails />
        <QuoteCircuitsDetails
          class="mt-2"
          :quote="quote"
        />
        <QuoteTotalDetails />

        <QuotePaymentsHistory
          v-if="canShowPaymentsHistory && !loadingQuote"
          @reload-quote="loadQuoteData"
        />

        <QuoteComments
          v-if="canShowComments"
          :quote="quote"
        />
      </div>
    </template>
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'

import QuoteComments from '@/modules/trade/quotes/components/QuoteComments.vue'
import QuoteExtraData from '@/modules/trade/quotes/components/QuoteExtraData.vue'
import QuoteTotalDetails from '@/modules/trade/quotes/components/QuoteTotalDetails.vue'
import QuoteCustomerData from '@/modules/trade/quotes/components/QuoteCustomerData.vue'
import QuoteDetailsOptions from '@/modules/trade/quotes/components/QuoteDetailsOptions.vue'
import QuotePaymentsHistory from '@/modules/trade/quotes/components/QuotePaymentsHistory.vue'
import QuoteProductsDetails from '@/modules/trade/quotes/components/QuoteProductsDetails.vue'
import QuoteCircuitsDetails from '@/modules/trade/quotes/components/QuoteCircuitsDetails.vue'
import SaveQuoteFormSkeleton from '@/modules/trade/quotes/components/SaveQuoteFormSkeleton.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    QuoteComments,
    QuoteExtraData,
    QuoteTotalDetails,
    QuoteCustomerData,
    QuoteDetailsOptions,
    QuotePaymentsHistory,
    QuoteCircuitsDetails,
    QuoteProductsDetails,
    SaveQuoteFormSkeleton,
  },
  props: {
    /**
     * Identificador de la cotización
     */
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      /**
       * Indica si se está cargando la cotización
       */
      loadingQuote: true,
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
    }),
    /**
     * Cotización
     */
    quote: {
      get() { return this.getQuote },
    },
    /**
     * Indica si se puede mostrar el historial de pagos
     */
    canShowPaymentsHistory() {
      return this.quote.quote[0].StatusText !== 'Cotización'
              && this.quote.quote[0].StatusText !== 'Enviado'
    },
    /**
     * Indica si se pueden mostrar los comentarios
     */
    canShowComments() {
      if (!this.quote.comments) {
        return false
      }
      // Get the status of the quote
      const status = this.quote.quote[0].StatusText
      // If the quote is rejected, approved, or canceled, show the comments section
      return status === 'Rechazado' || status === 'Aprobado' || status === 'Cancelado' || this.quote.comments.length > 0
    },
  },

  /**
  * Hook que se ejecuta cuando se monta el componente
  *
  * @summary Load the quote data
  */
  async created() {
    this.loadingQuote = true
    await this.loadQuoteData()
    this.loadingQuote = false
  },

  /**
   * Before destroy hook
   *
   * @summary Clear the form data when the component is destroyed
   */
  beforeDestroy() {
    this.clearFormData()
  },
  methods: {
    ...mapActions({
      loadQuote: 'quotes/loadQuote',
      loadPayments: 'quotes/loadPayments',
      clearFormData: 'quotes/clearFormData',
      setOriginProductRoute: 'products/setOriginProductRoute',
    }),

    /**
    * Carga la información de la cotización
    *
    * @summary Se ejecutará cuando se monte el componente. Se hace la petición al método
    * loadQuote del store enviando el id de la cotización, este método es asyncrono por
    * lo que se debe usar await para esperar a que termine su ejecución. Se asigna el primer
    * elemento de la respuesta a la variable quote
    * @exception Se valida si el error es 404, en caso de ser así se redirecciona a la página de error 404
    */
    async loadQuoteData() {
      try {
        // Show loading message
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        // Load quote data
        const response = await this.loadQuote(this.id)
        const quote = response.data[0].quote[0]

        // If quote has not a quote or sent status, load payments
        if (quote.StatusText !== 'Cotización' && quote.StatusText !== 'Enviado') {
          await this.loadPayments(quote.IdQuote)
        }

        // Set origin product route
        this.setOriginProductRoute({
          name: this.$route.name,
          params: {
            id: this.quote.quote[0].IdQuote,
          },
        })

        // Close loading message
        this.$swal.close()
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({ name: 'error-404' })
        }
      } finally {
        // Close loading message
        this.$swal.close()
      }
    },

    /**
     * Muestra una notificación
     * @param {String} title Título de la notificación
     * @param {String} text Description de la notificación
     * @param {String} variant Tipo de notificación
     */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
