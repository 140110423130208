<template>
  <div>
    <template v-if="formartedItems.length === 0 && quote.products.length === 0">

      <!-- #region::Alert when no circuits are available -->
      <b-alert
        variant="warning"
        class="my-2"
        show
      >
        <div class="alert-body">
          <span>No se agregaron circuitos a la cotización.</span>
        </div>
      </b-alert>
      <!-- #endregion::Alert when no circuits are available -->

    </template>

    <template v-else-if="formartedItems.length > 0">
      <b-form-row>

        <!-- #region::Title -->
        <b-col md="12">
          <h3 class="font-weight-bolder">
            Circuitos
          </h3>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Circuit list -->
        <b-col>
          <b-card
            class="mt-1 border border-dark shadow-none"
            no-body
          >
            <b-card-text>

              <!-- #region::Circuits list -->
              <b-table
                :items="formartedItems"
                :fields="columns"
                responsive="sm"
                class="my-0"
              >

                <!-- #region::A virtual column for name -->
                <template #cell(Name)="{ detailsShowing, item }">
                  <div class="d-flex justify-content-start">
                    <h6
                      class="align-self-center"
                      :class="{ 'text-white': detailsShowing }"
                    >
                      {{ item.Name }}
                    </h6>
                    <div
                      v-if="item.SoldOut && item.SoldOut === 'Si'"
                      class="sm-banner-alert alert-yellow"
                      role="alert"
                    >
                      Vendido
                    </div>
                    <div
                      v-if="item.Delete && item.Delete === 'Si'"
                      class="sm-banner-alert alert-gray"
                      role="alert"
                    >
                      No disponible
                    </div>
                    <div
                      v-if="item.Transfer && item.Transfer === 'Si'"
                      class="sm-banner-alert alert-pink"
                      role="alert"
                    >
                      Traspaso
                    </div>
                  </div>
                </template>
                <!-- #endregion::A virtual column for name -->

                <!-- #region::A virtual column for prices -->
                <template #cell(prices)="{ item }">
                  {{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}
                </template>
                <!-- #endregion::A virtual column for prices -->

                <!-- #region::A virtual column for offer price -->
                <template #cell(OfferPrice)="{ item }">
                  <div
                    v-if="item.OfferPrice"
                    class="d-flex"
                  >
                    <b-form-checkbox
                      v-if="item.ActiveOfferPrice"
                      style="margin-left: -25px !important;"
                      :checked="item.ActiveOfferPrice"
                      :disabled="true"
                    />
                    <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
                  </div>
                </template>
                <!-- #endregion::A virtual column for offer price -->

                <!-- #region::A virtual column for total -->
                <template #cell(total)="{ item }">
                  {{ totalUnitPrices(item) | currency }}
                </template>
                <!-- #endregion::A virtual column for total -->

                <!-- #region::A virtual column for toggle button -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  #cell(details)="{ detailsShowing, item }"
                >
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="toggleDetails(item)"
                  >
                    <feather-icon :icon="detailsShowing ? 'ChevronDownIcon': 'ChevronUpIcon'" />
                  </b-button>
                </template>
                <!-- #endregion::A virtual column for toggle button -->

                <!-- #region::Circuit details -->
                <template
                  v-if="$ability.can('read', 'Circuit')"
                  v-slot:row-details="{ item }"
                >
                  <b-card
                    no-body
                    class="mt-1"
                  >
                    <QuoteProductCollapsableItem
                      v-for="product in item.products"
                      :key="product.product.IdQuotesCircuitsProduct"
                      :product="makeProduct(product)"
                      :show-edit-button="false"
                      :show-delete-button="false"
                      :is-visible="false"
                      :disabled-form-components="true"
                      :show-pieces-list-actions-buttons="false"
                      :set-pieces-query="true"
                    />

                    <b-row v-if="!item.products">
                      <b-col>
                        <h3>Circuito {{ item.IdCircuit }}</h3>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
                <!-- #endregion::Circuit details -->

              </b-table>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- #endregion::Circuit list -->

      </b-form-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BRow, BFormRow, BCard, BCardText, BTable, BButton, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import buildProduct from '@/helpers/CircuitProductConverter'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BAlert,
    BTable,
    BButton,
    BFormRow,
    BCardText,
    BFormCheckbox,
    QuoteProductCollapsableItem: () => import('@/modules/trade/quotes/components/cards/QuoteProductCollapsableItem.vue'),
  },
  data() {
    return {
      circuits: [],
      currentItems: [],
      columns: [
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'total',
          label: 'Total',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: 'bg-light',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
    }),
    quote: { get() { return this.getQuote } },
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        this.$set(item, 'total', 0)
        this.offRow(item)
        return item
      })
    },
  },
  created() {
    const quoteCircuits = this.quote.circuits
    this.circuits = quoteCircuits

    this.circuits.forEach(circuit => {
      if (circuit.products) {
        circuit.products.forEach(product => {
          this.addQuotePieces(product.pieces)
        })
      }
    })
  },
  methods: {
    ...mapActions({
      addQuotePieces: 'quotes/addQuotePieces',
    }),
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (!row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        total: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', details: 'primary',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        total: '', IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', Location: '', details: '',
      })
    },

    /**
    * Precio total del circuito
    *
    * @summary Calcula el precio total del circuito a partir de los productos y piezas disponibles
    * @param {object} circuit - Elemento que representa un circuito de la lista
    * @return {integer} Total de los productos y piezas del circuito
    */
    totalUnitPrices(circuit) {
      let total = 0

      if (circuit.OfferPrice && circuit.ActiveOfferPrice) {
        return circuit.OfferPrice
      }

      if (circuit.products) {
        circuit.products.forEach(product => {
          const piecesCount = product.pieces.filter(
            piece => piece.Status === 1,
          ).length

          total += product.product.UnitPrice * piecesCount
        })
      }

      const baseCircuit = circuit
      baseCircuit.total = total

      return total
    },

    /**
    * Producto del circuito
    *
    * @summary Crea un producto que se adapta al componente del circuito. La informacion es proporcionada
    * por el servidor y es tratada para mostrarse de manera correcta
    * @param {object} circuitProduct - Producto del circuito proporcionado por el servidor
    * @return {object} producto que se muestra en el detalle de los circuitos
    */
    makeProduct(circuitProduct) {
      const product = buildProduct(circuitProduct)
      return product
    },
  },
}
</script>
