<template>
  <div>
    <b-card
      class="mt-1 border border-dark shadow-none"
      no-body
    >
      <b-card-text>

        <!-- #region::Payments list table -->
        <b-table
          v-if="availablePayments"
          :items="payments"
          :fields="columns"
          responsive
          class="my-0"
        >

          <!-- #region::Virtual column for status -->
          <template #cell(status)="{ item }">
            <b-badge :variant="getPaymentVariant(item.payment_confirmation)">
              {{ item.payment_confirmation }}
            </b-badge>
          </template>
          <!-- #endregion::Virtual column for status -->

          <!-- #region::Virtual column for count -->
          <template #cell(count)="{ item }">
            {{ item.payment | currency }}
          </template>
          <!-- #endregion::Virtual column for count -->

          <!-- #region::Virtual column for exchange type -->
          <template #cell(exchange)="{ item }">
            <template v-if="item.exchange_rate">
              {{ item.exchange_rate | currency }}
            </template>
            <span
              v-else
              class="text-nowrap"
            >Sin especificar</span>
          </template>
          <!-- #endregion::Virtual column for exchange type -->

          <!-- #region::Virtual column for total -->
          <template #cell(total)="{ item }">
            {{ item.total | currency }}
          </template>
          <!-- #endregion::Virtual column for total -->

          <!-- #region::Virtual column for payment method name -->
          <template #cell(payment_method_name)="{ item }">
            <span class="text-nowrap">{{ item.payment_method_name }}</span>
          </template>
          <!-- #endregion::Virtual column for payment method name -->

          <!-- #region::Virtual column for date payment -->
          <template #cell(voucher_date)="{ item }">
            {{ item.voucher_date | moment("DD/MM/YYYY") }}
          </template>
          <!-- #endregion::Virtual column for date payment -->

          <!-- #region::Virtual column for confirm/cancel payment -->
          <template #cell(confirmPayment)="{ item }">
            <template v-if="canConfirmOrCancelPayment(item)">
              <div class="d-flex">

                <!-- #region::Confirm payment button -->
                <b-button
                  v-if="$ability.can('confirm payment', 'Order')"
                  variant="principal-btn"
                  class="principal-btn"
                  size="sm"
                  :disabled="(loadingPaymentConfirmation && selectedPaymentId === item.id) || !canManagePayments"
                  @click="onConfirmPayment(item)"
                >
                  <b-spinner
                    v-if="loadingPaymentConfirmation && selectedPaymentId === item.id"
                    class="mr-50"
                    small
                  />
                  <span class="align-middle">Confirmar</span>
                </b-button>
                <!-- #endregion::Confirm payment button -->

                <!-- #region::Cancel payment button -->
                <b-button
                  v-if="$ability.can('cancel payment', 'Order')"
                  variant="delete-btn"
                  class="delete-btn ml-1"
                  size="sm"
                  :disabled="(loadingPaymentCancelation && selectedPaymentId === item.id) || !canManagePayments"
                  @click="onShowRejectedCommentModal(item)"
                >
                  <b-spinner
                    v-if="loadingPaymentCancelation && selectedPaymentId === item.id"
                    class="mr-50"
                    small
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
                <!-- #endregion::Cancel payment button -->

              </div>

            </template>

            <!-- #region::Label for TO CONFIRM payment status -->
            <b-badge
              v-else-if="item.payment_status === 'Por confirmar'"
              variant="light-primary"
            >
              {{ item.payment_status }}
            </b-badge>
            <!-- #endregion::Label for payment status -->

            <!-- #region::Label for NO CONFIRMED payment status -->
            <b-badge
              v-else-if="item.payment_status === 'No Confirmado'
                || item.payment_status === 'Cancelado'"
              variant="light-danger"
            >
              {{ item.payment_status }}
            </b-badge>
            <!-- #endregion::Label for payment status -->

            <!-- #region::Label for CONFIRMED payment status -->
            <b-badge
              v-else-if="item.payment_status === 'Confirmado'"
              variant="light-success"
            >
              {{ item.payment_status }}
            </b-badge>
            <!-- #endregion::Label for payment status -->

          </template>
          <!-- #endregion::Virtual column for confirm payment -->

          <!-- #region::A virtual column for preview/download -->
          <template #cell(receipt)="{ item }">

            <div class="d-flex justify-content-around">

              <!-- #region::Preview image button -->
              <b-button
                v-b-tooltip.hover.top="'Ver imagen'"
                variant="flat-primary"
                class="btn-icon"
                @click="$refs.imagePreviewModal.show(item.image)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- #endregion::Preview image button -->

              <!-- #region::Download receipt button -->
              <b-button
                variant="flat-success"
                @click="onDownloadPaymentTicket(item)"
              >
                <div class="d-flex justify-content-between">
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-black-100">Descargar</span>
                </div>
              </b-button>
            <!-- #endregion::Download receipt button -->
            </div>

          </template>
          <!-- #endregion::A virtual column for preview/download -->

          <!-- #region::Virtual column for created date -->
          <template #cell(created_at)="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <!-- #endregion::Virtual column for created date -->

          <!-- #region::A virtual column for actions -->
          <template #cell(actions)="{ item }">

            <template v-if="item.payment_status === 'Por confirmar'">

              <!-- #region::Edit payment button -->
              <b-button
                id="editPaymentButton"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                :disabled="loadingPaymentForm && selectedPaymentId === item.id"
                @click="onUpdatePayment(item)"
              >
                <b-spinner
                  v-if="loadingPaymentForm && selectedPaymentId === item.id"
                  small
                />
                <feather-icon
                  v-else
                  icon="Edit3Icon"
                />
              </b-button>
              <b-tooltip
                target="editPaymentButton"
                placement="top"
                triggers="hover"
              >
                Editar
              </b-tooltip>
            <!-- #endregion::Edit payment button -->

            </template>

          </template>
          <!-- #endregion::A virtual column for actions -->

        </b-table>
        <!-- #endregion::Payments list table -->

      </b-card-text>
    </b-card>

    <QuotePaymentModal
      v-if="$ability.can('confirm quote', 'Quote')"
      ref="quotePaymentModal"
      @reload-quote="$emit('reload-quote')"
    />

    <ImagePreviewModal ref="imagePreviewModal" />

    <CommentModal
      ref="commentModal"
      :action="validateCancellingComment"
      :title="'Cancelar pago'"
      :sub-title="'Comenta el motivo de cancelación'"
      :succes-title="'Pago cancelado'"
      :success-message="'El pago seleccionado ha sido cancelado correctamente.'"
    />
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BBadge, BButton, VBTooltip, BTable, BCard, BCardText, BSpinner, BTooltip,
} from 'bootstrap-vue'

import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuotePaymentModal from '@/modules/trade/quotes/components/modals/QuotePaymentModal.vue'
import CommentModal from '@/components/modals/CommentModal.vue'

import getError from '@/helpers/ErrorsHandler'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
// #endregion

export default {
  components: {
    BCard,
    BBadge,
    BTable,
    BButton,
    BSpinner,
    BTooltip,
    BCardText,
    ImagePreviewModal,
    QuotePaymentModal,
    CommentModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      columns: [
        {
          key: 'status',
          label: 'Estatus',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'IDD',
          label: 'ID',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'invoice',
          label: 'Folio de recibo',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'count',
          label: 'Cantidad',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'exchange',
          label: 'T.C.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'total',
          label: 'Total',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'payment_method_name',
          label: 'Forma de pago',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'voucher_date',
          label: 'Comprobante',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'invoice_required',
          label: 'Factura requerida',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'confirmPayment',
          label: 'Confirmar pago',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'receipt',
          label: 'Recibo',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'created_at',
          label: 'Registrado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thStyle: 'text-transform: none;',
          thClass: this.$ability.can('edit payment', 'Order') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('edit payment', 'Order') ? '' : 'd-none',
        },
      ],
      selectedPayment: null,
      selectedPaymentId: '',
      loadingPaymentForm: false,
      loadingPaymentCancelation: false,
      loadingPaymentConfirmation: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
      getPayments: 'quotes/getPayments',
    }),
    quoteInfo: {
      get() {
        const [quoteInfo] = this.getQuote.quote
        return quoteInfo
      },
    },
    payments: {
      get() { return this.getPayments },
    },
    availablePayments() {
      return this.payments.length > 0
    },
    /**
     * Indica si el usuario puede gestionar usuarios empresa
     */
    canManagePayments() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  watch: {
    payments() {
      this.verifyPaymentsStatus()
    },
  },
  created() {
    this.verifyPaymentsStatus()
  },
  methods: {
    ...mapActions({
      downloadImage: 'quotes/downloadImage',
      cancelPayment: 'quotes/cancelPayment',
      confirmPayment: 'quotes/confirmPayment',
    }),
    onDownloadPaymentTicket(payment) {
      this.downloadImage(payment.id)
    },
    // * 22/12/2022 - TAG: Update payment by seller or wholesaler
    async onUpdatePayment(payment) {
      this.selectedPaymentId = payment.id
      this.$root.$emit('bv::hide::tooltip', 'editPaymentButton')
      this.loadingPaymentForm = true

      await this.$refs.quotePaymentModal.showModalAndLoadExistingPayment(payment)

      this.loadingPaymentForm = false
    },
    // * 22/12/2022 - TAG: Confirm payment by admin
    async onConfirmPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.selectedPaymentId = payment.id
        this.loadingPaymentConfirmation = true

        const formData = new FormData()
        formData.append('id', payment.id)

        const response = await this.confirmPayment(formData)

        if (response.data.success) {
          this.$set(payment, 'payment_status', 'Confirmado')
          this.$set(payment, 'payment_confirmation', 'Pagado')

          this.showToast(
            'Pago confirmado',
            'El pago seleccionado ha sido confirmado correctamente.',
            'success',
          )
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.loadingPaymentConfirmation = false
        this.$swal.close()
      }
    },
    /**
     * @summary Muestra el modal para cancelar un pago y setea el pago seleccionado
     * @param {Object} payment - Objeto con la información del pago
     * @returns {void}
     */
    onShowRejectedCommentModal(payment) {
      this.selectedPayment = payment
      this.$refs.commentModal.showModal()
    },
    /**
     * @summary Valida el comentario de cancelación y ejecuta la acción de cancelar el pago
     * @param {string} comment - Comentario de cancelación
     * @returns {void}
     */
    validateCancellingComment(comment) {
      this.onCancelPayment(this.selectedPayment, comment)
    },
    async onCancelPayment(payment, comment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.selectedPaymentId = payment.id
        this.loadingPaymentCancelation = true

        const response = await this.cancelPayment({
          id: payment.id,
          comment,
        })

        if (response.data.success) {
          this.$set(payment, 'payment_status', 'Cancelado')
          this.$set(payment, 'payment_confirmation', 'Cancelado')

          this.showToast(
            'Pago cancelado',
            'El pago seleccionado ha sido cancelado correctamente.',
            'success',
          )

          this.$emit('reload-quote')
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.loadingPaymentCancelation = false
        this.$swal.close()
        this.$refs.commentModal.hideModal()
      }
    },
    canConfirmOrCancelPayment(item) {
      return item.payment_status === 'Por confirmar'
              && this.$ability.can('confirm payment', 'Order')
              && (this.quoteInfo.StatusText === 'Solicitud' || this.quoteInfo.StatusText === 'Rechazado')
    },
    getPaymentVariant(status) {
      switch (status) {
        case 'Pendiente':
          return 'light-primary'
        case 'Cancelado':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    verifyPaymentsStatus() {
      const existingPendingPayments = payment => payment.payment_status === 'Por confirmar'
      const existsPendingPayments = this.payments.some(existingPendingPayments)

      if (!existsPendingPayments) {
        this.$set(this.columns[11], 'thClass', 'd-none')
        this.$set(this.columns[11], 'tdClass', 'd-none')
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .text-black-100 {
    color: black
  }
</style>
