<template>
  <div>
    <template v-if="products.length === 0 && quote.circuits.length === 0">

      <!-- #region::Alert when no circuits are available -->
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>No se agregaron productos a la cotización.</span>
        </div>
      </b-alert>
      <!-- #endregion::Alert when no circuits are available -->

    </template>

    <template v-else-if="products.length > 0">
      <b-form-row>

        <!-- #region::Title -->
        <b-col md="12">
          <h3 class="font-weight-bolder">
            Productos
          </h3>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Products list -->
        <b-col>
          <app-collapse
            accordion
            type="margin"
          >
            <QuoteProductCollapsableItem
              v-for="product in products"
              :key="product.IdProduct"
              :product="product"
              :show-edit-button="false"
              :show-delete-button="false"
              :disabled-form-components="true"
              :load-edit-product-modal="false"
              :is-visible="false"
              :show-pieces-list-actions-buttons="false"
            />
          </app-collapse>
        </b-col>
        <!-- #endregion::Products list -->

      </b-form-row>
    </template>
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { BCol, BFormRow, BAlert } from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import buildProduct from '@/helpers/ProductConverter'
// #endregion

export default {
  components: {
    BCol,
    BAlert,
    BFormRow,
    AppCollapse,
    QuoteProductCollapsableItem:
      () => import('@/modules/trade/quotes/components/cards/QuoteProductCollapsableItem.vue'),
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
    }),
    quote: { get() { return this.getQuote } },
    products: {
      get() {
        const quoteProducts = this.quote.products
        const formattedProducts = []
        quoteProducts.forEach(quoteProduct => {
          const product = buildProduct(quoteProduct)
          formattedProducts.push(product)
        })

        return formattedProducts
      },
    },
  },
  created() {
    this.products.forEach(product => {
      this.addQuotePieces(product.pieces)
    })
  },
  methods: {
    ...mapActions({
      addQuotePieces: 'quotes/addQuotePieces',
    }),
  },
}
</script>
