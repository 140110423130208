<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card class="border border-dark shadow-none">
          <div>
            <b-row>

              <!-- #region::Title -->
              <b-col class="align-self-end">
                <h4 class="font-weight-bolder">
                  Total de la cotización
                </h4>
              </b-col>
              <!-- #endregion::Title -->

              <!-- #region begin::Collapse card button -->
              <b-button
                :variant="visible ? 'flat-primary' : 'flat-secondary'"
                class="btn-icon rounded-circle mr-1"
                @click="collapse"
              >
                <feather-icon :icon="iconToggle" />
              </b-button>
              <!-- #endregion end::Collapse card button -->

            </b-row>
          </div>

          <b-collapse
            :id="collapseItemId"
            v-model="visible"
            role="tabpanel"
          >
            <div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal USD</span>
                <span>{{ quoteInfo.SubtotalUsd || 0 | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Tipo de cambio</span>
                <span>{{ quoteInfo.ExchangeRate || 0 | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Descuento</span>
                <span v-if="quoteInfo.Discount">{{ `${quoteInfo.Discount}%` || '0%' }}</span>
                <span v-else>0.00%</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal MXN</span>
                <span>{{ quoteInfo.SubtotalMxn || 0 | currency }}</span>
              </div>
              <hr>
              <div
                v-if="quoteInfo.AmountInvoiced"
                class="d-flex justify-content-between"
              >
                <span>Monto a facturar</span>
                <span>{{ quoteInfo.AmountInvoiced || 0 | currency }}</span>
              </div>
              <hr v-if="quoteInfo.AmountInvoiced">
              <div class="d-flex justify-content-between">
                <span>IVA</span>
                <span>{{ quoteInfo.Iva || 0 | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Envío</span>
                <span>{{ quoteInfo.ShippingCost || 0 | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Instalación</span>
                <span>{{ quoteInfo.InstallationCost || 0 | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between font-weight-bolder">
                <span>Total MXN</span>
                <span>{{ quoteInfo.TotalMxn || 0 | currency }}</span>
              </div>
            </div>
          </b-collapse>

        </b-card>
      </b-col>

      <!-- #region::Invoice data -->
      <b-col md="4">
        <InvoiceData
          :quote="quote"
          :show-toggle-button="true"
          :show-use-other-button="false"
          :show-business-name="false"
        />
      </b-col>
      <!-- #endregion::Invoice data -->

      <!-- #region::Donwloading tax situation proof -->
      <b-col
        v-if="canShowDownloadTaxSituationProof"
        md="4"
      >
        <QuoteDownloadTaxSItuationProof
          :id-model="quoteInfo.ID"
          :id-model-type="'quoteID'"
          :file-url="quoteInfo.tax_situation"
          :file-name="quoteInfo.tax_situation_name"
        />
      </b-col>
      <!-- #endregion::Donwloading tax situation proof -->

    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton, BCollapse,
} from 'bootstrap-vue'

import InvoiceData from '@/modules/trade/quotes/components/form/InvoiceData.vue'
import QuoteDownloadTaxSItuationProof from '@/modules/trade/quotes/components/QuoteDownloadTaxSItuationProof.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    InvoiceData,
    QuoteDownloadTaxSItuationProof,
  },
  data() {
    return {
      visible: false,
      collapseItemId: '4',
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
    }),
    /**
     * Cotización
     */
    quote: {
      get() { return this.getQuote },
    },
    /**
     * Información de la cotización
     */
    quoteInfo: {
      get() {
        const [quoteInfo] = this.quote.quote
        return quoteInfo
      },
    },
    /**
     * Indica si se puede mostrar el botón de descarga de comprobante de situación fiscal
     */
    canShowDownloadTaxSituationProof: {
      get() {
        return (this.quoteInfo.tax_situation && this.quoteInfo.tax_situation_name)
        && this.$ability.can('download tax situation', 'Order')
      },
    },
  },

  methods: {
    /**
     * Colapsa el card de total de la cotización
     */
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },

    /**
     * Cambia el icono de la flecha de colapsar
     */
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
