<template>
  <div>
    <b-form-row align-v="center">

      <!-- #region::Return to quotes list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small>
          <router-link
            :to="{ name: 'quotes-home' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return to quotes list link -->

      <!-- #region::Title with tag -->
      <b-col>
        <div class="d-flex justify-content-left">
          <h3 class="align-self-center">
            Cotización #{{ quoteInfo.ID }}
          </h3>
          <div
            :class="[statusBorderStyle, statusColorStyle]"
            role="alert"
          >
            {{ quoteInfo.StatusText }}
          </div>
        </div>
      </b-col>
      <!-- #endregion::Title with tag -->

      <!-- #region::Download csv button -->
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="flat-success"
          class="csv-btn"
          :disabled="downloadingFile"
          @click="onDownloadPDF"
        >
          <b-spinner
            v-if="downloadingFile"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!downloadingFile"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">Descargar PDF</span>
        </b-button>
      </b-col>
      <!-- #endregion::Download csv button -->

      <!-- #region::Cancel quote button -->
      <b-col
        v-if="canCancelQuote"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="cancelingQuote"
          @click="onCancelQuote"
        >
          <b-spinner
            v-if="cancelingQuote"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel quote button -->

      <!-- #region::Edit quote button -->
      <b-col
        v-if="canEditQuote"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          @click="$router.push({ name: 'update-quote', params: { id: quoteInfo.IdQuote }})"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Edit quote button -->

      <!-- #region::Approve quote button -->
      <b-col
        v-if="canApproveQuote"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          :disabled="approvingQuote"
          @click="onApproveQuote"
        >
          <b-spinner
            v-if="approvingQuote"
            class="mr-50"
            small
          />
          <span class="align-middle">Aceptar solicitud</span>
        </b-button>
      </b-col>
      <!-- #endregion::Approve quote button -->

      <!-- #region::Reject quote button -->
      <b-col
        v-if="canRejectQuote"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onRejectQuote"
        >
          Rechazar solicitud
        </b-button>
      </b-col>
      <!-- #endregion::Reject quote button -->

      <!-- #region::Delete quote button -->
      <b-col
        v-if="canDeleteQuote"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="deletingQuote"
          @click="onDeleteQuote"
        >
          <b-spinner
            v-if="deletingQuote"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!deletingQuote"
            icon="Trash2Icon"
            class="mr-50"
          />
          <span class="align-middle">Eliminar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Delete quote button -->

    </b-form-row>

    <b-form-row align-h="start">

      <!-- #region::Send quote to customer button -->
      <b-col
        v-if="canShowSendToCostumerEmail"
        md="auto"
      >
        <b-button
          variant="flat-primary"
          :disabled="sendigCustomerEmail"
          @click="onSendToCustomer"
        >
          <b-spinner
            v-if="sendigCustomerEmail"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!sendigCustomerEmail"
            icon="MailIcon"
            class="mr-50"
          />
          <span class="align-middle">Enviar al cliente</span>
        </b-button>
      </b-col>
      <!-- #endregion::Send quote to customer button -->

      <!-- #region::Confirm quote button -->
      <b-col
        v-if="canShowConfirmQuoteButton"
        md="auto"
      >
        <b-button
          variant="flat-success"
          :disabled="verifyingConfirmQuote"
          @click="onConfirmQuote"
        >
          <b-spinner
            v-if="verifyingConfirmQuote"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!verifyingConfirmQuote"
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Confirmar cotización</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm quote button -->

    </b-form-row>

    <!-- #region::Modal for making payment and confirm quote -->
    <QuotePaymentModal
      v-if="$ability.can('confirm quote', 'Quote')"
      ref="quotePaymentModal"
      :show-upload-tax-situation-proof-modal="quoteInfo.StatusText !== 'Rechazado'"
      @reload-quote="$emit('reload-quote')"
      @on:show-upload-tax-situation-proof-modal="showUploadFileConfimation"
    />
    <!-- #endregion::Modal for making payment and confirm quote -->

    <!-- #region::Modal for making rejected quote comment -->
    <RejectedQuoteCommentModal
      v-if="$ability.can('reject request', 'Quote')"
      ref="quoteRejectCommentModal"
      @reload-quote="$emit('reload-quote')"
    />
    <!-- #endregion::Modal for making rejected quote comment -->

    <!-- #region::Modal for making rejected quote comment -->
    <UploadTaxSituationProofModal
      v-if="$ability.can('save tax situation', 'Order')"
      ref="uploadTaxSituationProofModal"
      @reload-quote="$emit('reload-quote')"
    />
    <!-- #endregion::Modal for making rejected quote comment -->

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import {
  BCol, BButton, BFormRow, BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuotePaymentModal from '@/modules/trade/quotes/components/modals/QuotePaymentModal.vue'
import RejectedQuoteCommentModal from '@/modules/trade/quotes/components/modals/RejectedQuoteCommentModal.vue'
import UploadTaxSituationProofModal from '@/modules/trade/quotes/components/modals/UploadTaxSituationProofModal.vue'

import getError from '@/helpers/ErrorsHandler'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
// #endregion

export default {
  components: {
    BCol,
    BButton,
    BSpinner,
    BFormRow,
    QuotePaymentModal,
    RejectedQuoteCommentModal,
    UploadTaxSituationProofModal,
  },
  data() {
    return {
      deletingQuote: false,
      cancelingQuote: false,
      approvingQuote: false,
      rejectingQuote: false,
      downloadingFile: false,
      sendigCustomerEmail: false,
      verifyingConfirmQuote: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
      getPayments: 'quotes/getPayments',
      getQuotePieces: 'quotes/getQuotePieces',
    }),
    quoteInfo: {
      get() {
        const [quoteInfo] = this.getQuote.quote
        return quoteInfo
      },
    },
    circuits: {
      get() {
        return this.getQuote.circuits
      },
    },
    products: {
      get() {
        return this.getQuote.products
      },
    },
    quotePieces: {
      get() { return this.getQuotePieces },
    },
    payments: {
      get() { return this.getPayments },
    },
    statusBorderStyle() {
      return this.quoteInfo.StatusText === 'Orden' ? 'banner-alert-bordered' : 'banner-alert'
    },
    statusColorStyle() {
      switch (this.quoteInfo.StatusText) {
        case 'Enviado':
          return 'alert-yellow'
        case 'Solicitud':
          return 'alert-green'
        case 'Orden':
          return 'alert-green-white'
        case 'Rechazado':
          return 'alert-orange'
        case 'Cancelado':
          return 'alert-gray'
        default:
          return 'alert-blue'
      }
    },
    canCancelQuote() {
      return this.$ability.can('cancel quote', 'Quote')
              && this.quoteInfo.StatusText !== 'Cancelado'
              && this.quoteInfo.StatusText !== 'Orden'
    },
    canEditQuote() {
      return this.$ability.can('edit', 'Quote')
              && (this.quoteInfo.StatusText === 'Cotización'
              || this.quoteInfo.StatusText === 'Enviado')
    },
    canShowSendToCostumerEmail() {
      return this.$ability.can('send quote', 'Quote')
              && this.quoteInfo.StatusText !== 'Cancelado'
    },
    canShowConfirmQuoteButton() {
      return this.$ability.can('confirm quote', 'Quote')
              && (this.quoteInfo.StatusText === 'Cotización'
              || this.quoteInfo.StatusText === 'Enviado'
              || this.quoteInfo.StatusText === 'Rechazado')
    },
    canApproveQuote() {
      return this.$ability.can('approve request', 'Quote')
              && this.quoteInfo.StatusText === 'Solicitud'
              && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
    canRejectQuote() {
      return this.$ability.can('reject request', 'Quote')
              && this.quoteInfo.StatusText === 'Solicitud'
              && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
    canDeleteQuote() {
      return this.$ability.can('delete', 'Quote')
              && this.quoteInfo.StatusText === 'Cancelado'
              && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  methods: {
    ...mapActions({
      deleteQuote: 'quotes/deleteQuote',
      approveQuote: 'quotes/approveQuote',
      canMakePayment: 'quotes/canMakePayment',
      downloadPDFFile: 'quotes/downloadPDFFile',
      cancelCreatedQuote: 'quotes/cancelCreatedQuote',
      confirmRefusedQuote: 'quotes/confirmRefusedQuote',
      sendEmailToCustomer: 'quotes/sendEmailToCustomer',
    }),

    /**
     * Descarga el archivo PDF de la cotización
     */
    async onDownloadPDF() {
      this.downloadingFile = true
      await this.downloadPDFFile({ id1: this.quoteInfo.IdQuote, id2: this.quoteInfo.ID })
      this.downloadingFile = false
    },

    /**
     * Envia un correo electrónico al cliente con la cotización
     * @resumen Valida que la cotización no tenga circuitos o productos en orden de traspaso
     * y que todos los productos y circuitos tengan un método de envío especificado
     * @exception Error si el correo no puede ser enviado
     */
    async onSendToCustomer() {
      if (this.circuitsInTransferOrder()) {
        this.showToast(
          'Error de validación',
          'Uno o más circuitos se encuentran en una orden de traspaso.',
          'warning',
        )

        return
      }

      if (this.piecesInTransferOrder()) {
        this.showToast(
          'Error de validación',
          'Una o más piezas de los productos se encuentran en una orden de traspaso.',
          'warning',
        )

        return
      }

      this.sendigCustomerEmail = true

      const formData = new FormData()
      formData.append('id', this.quoteInfo.IdQuote)

      try {
        await this.sendEmailToCustomer(formData)
        await this.$emit('reload-quote')
        this.showToast(
          'Correo enviado',
          'El correo electrónico ha sido enviado correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.sendigCustomerEmail = false
      }
    },

    /**
     * Realiza diferntes validaciones para confirmar la cotización
     * @resumen Verifica que la cotización tenga al menos un producto o circuito y
     * que todos los productos y circuitos tengan un método de envío especificado
     * @exception Error si la cotización no puede ser confirmada
     */
    async onConfirmQuote() {
      this.verifyingConfirmQuote = true

      try {
        if (!this.quoteInfo.DeliveryType) {
          this.showToast(
            'Error en la confirmación',
            'No se ha especificado el tipo de entrega para la cotización.',
            'warning',
          )

          return
        }

        if (!this.products.length > 0 && !this.circuits.length > 0) {
          this.showToast(
            'Error en la confirmación',
            'La cotización no puede ser confirmada ya que no cuenta con al menos un producto o circuito.',
            'warning',
          )

          return
        }

        if (!this.productsWithDeliveryMethod()) {
          this.showToast(
            'Error de validación',
            'Uno o más productos no cuenta con método de envío especificado.',
            'warning',
          )

          return
        }

        if (!this.circuitsWithDeliveryMethod()) {
          this.showToast(
            'Error de validación',
            'Uno o más circuitos cuentan con productos sin método de envío especificado.',
            'warning',
          )

          return
        }

        if (this.circuitsInTransferOrder()) {
          this.showToast(
            'Error de validación',
            'Uno o más circuitos se encuentran en una orden de traspaso.',
            'warning',
          )

          return
        }

        if (this.piecesInTransferOrder()) {
          this.showToast(
            'Error de validación',
            'Una o más piezas de los productos se encuentran en una orden de traspaso.',
            'warning',
          )

          return
        }

        if (this.quoteInfo.StatusText === 'Rechazado') {
          const formData = new FormData()
          formData.append('id', this.quoteInfo.IdQuote)

          const response = await this.confirmRefusedQuote(formData)
          const { needPayment } = response.data

          if (needPayment) {
            this.$refs.quotePaymentModal.showModal(this.quoteInfo)
            return
          }

          await this.$emit('reload-quote')
          this.showToast(
            'Cotización confirmada',
            'La cotización ha sido confirmada correctamente.',
            'success',
          )
        } else {
          await this.canMakePayment(this.quoteInfo.IdQuote)

          if (!this.soldOutPieces()) {
            this.$refs.quotePaymentModal.showModal(this.quoteInfo)
          }
        }
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.verifyingConfirmQuote = false
      }
    },

    /**
     * Aprueba la cotización
     * @resumen Valida que la cotización no tenga un pago confirmado
     * y aprueba la cotización
     * @exception Error si el pago no puede ser confirmado
     */
    async onApproveQuote() {
      const payment = this.payments[0]
      if (payment.payment_status !== 'Por confirmar') {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('id', this.quoteInfo.IdQuote)

        try {
          this.approvingQuote = true
          await this.approveQuote(formData)

          this.showToast(
            'Cotización aceptada',
            'La cotización ha sido aceptada correctamente.',
            'success',
          )
          this.$emit('reload-quote')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.approvingQuote = false
        }
      } else {
        this.showToast(
          'Aceptación cancelada',
          'Para aprobar la solictud se tiene que confirmar el pago registrado.',
          'warning',
        )
      }
    },

    /**
     * Cancela la cotización
     * @exception Error si la cotización no puede ser cancelada
     */
    async onCancelQuote() {
      this.$swal({
        title: 'Espere por favor',
        allowOutsideClick: false,
      })

      this.$swal.showLoading()

      this.cancelingQuote = true

      const formData = new FormData()
      formData.append('id', this.quoteInfo.IdQuote)

      try {
        await this.cancelCreatedQuote(formData)
        await this.$emit('reload-quote')
        this.showToast(
          'Cotización cancelada',
          'La cotización ha sido cancelada correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.cancelingQuote = false
        this.$swal.close()
      }
    },

    /**
     * Elimina la cotización
     * @exception Error si la cotización no puede ser eliminada
     */
    async onDeleteQuote() {
      try {
        this.deletingQuote = true
        await this.deleteQuote(this.quoteInfo.IdQuote)
        this.showDeletedQuoteMessage('¡La cotización ha sido eliminada correctamente!')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.deletingQuote = false
      }
    },

    /**
     * Muestra el modal para rechazar la cotización
     */
    onRejectQuote() {
      this.$refs.quoteRejectCommentModal.showModal(this.quoteInfo)
    },

    /**
     * Valida si hay piezas vendidas
     */
    soldOutPieces() {
      const isSoldOut = quotePiece => quotePiece.SoldOut === 'Si'
      const soldOut = this.quotePieces.some(isSoldOut)

      if (soldOut) {
        this.showToast(
          'Cotización no válida',
          'Una o más piezas de los productos ya no están disponibles o han sido apartadas.',
          'warning',
        )
      }

      return soldOut
    },

    /**
     * Valida si todos los productos tienen un método de envío
     */
    productsWithDeliveryMethod() {
      let withDeliveryMethod = true

      const hasNotDeliveryMethod = product => !product.product.MethodDelivery
      const notDeliveryMethod = this.products.some(hasNotDeliveryMethod)

      if (notDeliveryMethod) {
        withDeliveryMethod = false
      }

      return withDeliveryMethod
    },

    /**
     * Valida que las piezas de los productos no estén en orden de traspaso
     */
    piecesInTransferOrder() {
      let inTransferOrder = false

      this.products.forEach(product => {
        const isPieceInTransferOrder = piece => piece.Transfer === 'Si'
        const pieceInTransferOrder = product.pieces.some(isPieceInTransferOrder)

        if (pieceInTransferOrder) {
          inTransferOrder = true
        }
      })

      return inTransferOrder
    },

    /**
     * Valida que todos los productos de los circuitos tengan un método de envío
     */
    circuitsWithDeliveryMethod() {
      let withDeliveryMethod = true

      this.circuits.forEach(quoteCircuit => {
        const hasNotDeliveryMethod = product => !product.product.MethodDelivery
        const notDeliveryMethod = quoteCircuit.products
          ? quoteCircuit.products.some(hasNotDeliveryMethod)
          : false

        if (notDeliveryMethod) {
          withDeliveryMethod = false
        }
      })

      return withDeliveryMethod
    },

    /**
     * Valida que los circuitos no estén en orden de traspaso
     */
    circuitsInTransferOrder() {
      let inTransferOrder = false

      const isPieceInTransferOrder = circuit => circuit.Transfer === 'Si'
      const pieceInTransferOrder = this.circuits.some(isPieceInTransferOrder)

      if (pieceInTransferOrder) {
        inTransferOrder = true
      }

      return inTransferOrder
    },

    /**
    * Mensaje de notificación
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },

    /**
     * Mensaje de confirmación
     * @summary Despliega un mensaje de confirmación
     * @param {string} message - Mensaje de confirmación
     */
    showDeletedQuoteMessage(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'quotes-home' })
        }
      })
    },

    /**
     * Muestra el modal de confirmación para adjuntar la constancia de situación fiscal
     */
    async showUploadFileConfimation() {
      const result = await this.$swal({
        title: 'Constancia de situación fiscal',
        text: '¿Desea adjuntar una constancia de situación fiscal junto con este pago?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí, cargar ahora',
        cancelButtonText: 'No, cargar después',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })

      if (result.isConfirmed) {
        this.$refs.uploadTaxSituationProofModal.showModal({ id: this.quoteInfo.ID, type: 'quote' })
      } else {
        this.$emit('reload-quote')
      }
    },
  },
}
</script>
