<template>
  <b-card class="blue-card">
    <b-card-text>
      <b-row>
        <b-col>
          <InformationBasicCard
            title="Productos"
            :tags="[{ value: quoteInfo.Qty, type: 'string' }]"
          />
        </b-col>
        <b-col>
          <InformationBasicCard
            title="Tipo de entrega"
            :tags="[{ value: quoteInfo.DeliveryType, type: 'string' }]"
          />
        </b-col>
        <b-col>
          <InformationBasicCard
            title="Solicitud de factura"
            :tags="[{ value: quoteInfo.Invoice, type: 'string' }]"
          />
        </b-col>
        <b-col>
          <InformationBasicCard
            title="Fecha de entrega requerida"
            :tags="[{ value: quoteInfo.RequiredDeliveryDate, type: 'date' }]"
          />
        </b-col>
        <b-col>
          <InformationBasicCard
            title="Ubicación"
            :tags="[{ value: quoteInfo.location, type: 'date' }]"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    InformationBasicCard,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quoteInfo: null,
    }
  },
  created() {
    const [quoteInfo] = this.quote.quote
    this.quoteInfo = quoteInfo
  },
}
</script>

<style lang="scss" scoped>
b-card {
  background-color: blue
}
</style>
