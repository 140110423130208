<template>
  <div>
    <b-card
      no-body
      class="border border-dark shadow-none"
    >
      <b-card-body>
        <b-card-text
          v-for="comment in quote.comments"
          :key="comment.date"
          class="d-flex justify-content-right"
        >
          <div>
            <avatar
              :username="comment.user.Name"
              background-color="#0053D3"
              color="#fff"
            />
          </div>
          <div class="ml-2">
            <h4 class="font-weight-bolder">
              {{ comment.user.Name }}
              <span v-if="comment.ID">
                · ID {{ comment.ID }}
              </span>
            </h4>
            <h6>{{ comment.comment }}</h6>
            <small class="text-muted">{{ comment.date | moment("DD/MM/YYYY") }}</small>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardBody,
} from 'bootstrap-vue'

import Avatar from 'vue-avatar'

export default {
  components: {
    BCard,
    Avatar,
    BCardText,
    BCardBody,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
}
</script>
