<template>
  <div v-if="paymentStatus && payments.length > 0">
    <b-row>

      <!-- #region::Title -->
      <b-col md="12">
        <h3 class="font-weight-bolder">
          Historial de pagos
        </h3>
      </b-col>
      <!-- #endregion::Title -->

      <b-col md="6">
        <IconDetailsCard :card="percentagePaidCard" />
      </b-col>
      <b-col md="6">
        <IconDetailsCard :card="paymentStatusCard" />
      </b-col>

      <b-col md="12">
        <QuotePaymentsHitoryList @reload-quote="$emit('reload-quote')" />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCol, BRow,
} from 'bootstrap-vue'

import IconDetailsCard from '@/components/cards/IconDetailsCard.vue'
import QuotePaymentsHitoryList from '@/modules/trade/quotes/components/lists/QuotePaymentsHitoryList.vue'

export default {
  components: {
    BCol,
    BRow,
    IconDetailsCard,
    QuotePaymentsHitoryList,
  },
  computed: {
    ...mapGetters({
      getPaymentStatus: 'quotes/getPaymentStatus',
      getPercentagePaid: 'quotes/getPercentagePaid',
      getPayments: 'quotes/getPayments',
    }),
    paymentStatus: {
      get() { return this.getPaymentStatus },
    },
    percentagePaid: {
      get() { return this.getPercentagePaid },
    },
    payments: {
      get() { return this.getPayments },
    },
    paymentStatusCard() {
      return {
        icon: 'ClockIcon',
        title: 'Estatus del pago',
        text: this.paymentStatus,
        styles: ['blue-style'],
      }
    },
    percentagePaidCard() {
      return {
        icon: 'ClockIcon',
        title: 'Porcentaje pagado',
        text: `${this.percentagePaid}%`,
      }
    },
  },
}
</script>
